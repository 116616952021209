import React, { useEffect } from 'react';
import {
    noop as _noop
} from 'lodash';
import PropTypes from 'prop-types';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { useHistory } from 'react-router-dom';
import { E1PLoader } from 'e1p-capability-policyjob-react';

function RedirectComponent(props) {
    const { authUserData, isAuthenticated } = props;
    const history = useHistory();

    useEffect(() => {
        if (isAuthenticated && authUserData) {
            if (authUserData?.permissions_Ext.includes('actview_ext')) {
                return history.push('/activities');
            }

            return history.push('/accounts');
        }

        return _noop();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authUserData, isAuthenticated]);

    return (<E1PLoader loaded={!!authUserData} />);
}

RedirectComponent.propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    authUserData: PropTypes.shape({}).isRequired
};

export default withAuthenticationContext(RedirectComponent);
