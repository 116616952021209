import React, { Component } from 'react';
import { Icon } from '@jutro/components';
import { TranslatorContext } from '@jutro/locale';
import PropTypes from 'prop-types';
import _ from 'lodash';
import appConfig from 'app-config';
import { AccountService } from 'e1p-capability-gateway';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { AccountClaims } from 'gw-capability-gateway-claim-react';
import { AccountCommission } from 'gw-capability-gateway-commission-react';
import { ViewModelForm } from '@xengage/gw-portals-viewmodel-react';
import { isCapabilityEnabled } from '@xengage/gw-portals-config-js';
import { E1PLoader } from 'e1p-capability-policyjob-react';
import CommonRoutes from '../../Common/CommonRouting';
import metadata from './AccountDetails.metadata.json5';
import styles from './AccountDetails.module.scss';
import pageRouting from '../../Common/Accounts-config.json5';
import '../../Policies/Policies.messages';

const { capabilitiesConfig } = appConfig;

class AccountDetails extends Component {
    static contextType = TranslatorContext;

    static propTypes = {
        match: PropTypes.shape({
            path: PropTypes.string,
            params: PropTypes.shape({
                accountNumber: PropTypes.string,
                type: PropTypes.string
            }),
            url: PropTypes.string
        }).isRequired,
        location: PropTypes.shape({
            state: PropTypes.string,
            pathname: PropTypes.string
        }).isRequired,

        isAuthenticated: PropTypes.bool,
        authHeader: PropTypes.shape({}),
        children: PropTypes.shape({}),
        authUserData: PropTypes.shape({ publicID: PropTypes.string })
    };

    static defaultProps = {
        isAuthenticated: undefined,
        authHeader: undefined,
        children: undefined,
        authUserData: undefined
    }

    constructor(props) {
        super(props);
        this.state = {
            currentView: undefined,
            commissionTurnedOn: false,
            accountData: {},
            accountBillingData: {},
            isLoading: true
        };
    }

    componentDidMount() {
        this.getAccountDetails();
    }

    componentDidUpdate(preProp, preState) {
        const {
            location: { pathname },
            match: {
                params: { accountNumber }
            }
        } = this.props;

        if (preProp.location.pathname.indexOf(accountNumber) === -1) {
            this.getAccountDetails();
        }

        const currentPage = _.last(pathname.split('/'));

        if (preState.currentView !== currentPage) {
            this.updateCurrentView(currentPage);
        }
    }

    getAccountDetails = () => {
        this.setState({
            isLoading: true
        });

        const { authUserData } = this.props;
        const {
            isAuthenticated,
            authHeader,
            match: {
                params: { accountNumber }
            },
            location: { state: redirectPath }
        } = this.props;

        const commissionTurnedOn = authUserData?.permissions_Ext.includes('viewcommissions');

        if (isAuthenticated) {
            this.setState({ commissionTurnedOn });

            const getPathname = _.get(this.props, 'location.pathname') || '';
            const getActiveTile = getPathname.replace(/\/accounts\/\d+\//g, '');

            AccountService.getAccountDetails(accountNumber, authHeader)
                .then((accountDetailsResponse) => {
                    this.setState({
                        accountData: accountDetailsResponse,
                        currentView: redirectPath || getActiveTile || 'summary'
                    });

                    if (isCapabilityEnabled({ capabilitiesConfig, capabilityName: 'billing' })) {
                        AccountService.getAccountBillingData(accountNumber, authHeader).then(
                            (accountBillingData) => {
                                this.setState({
                                    accountBillingData,
                                    isLoading: false
                                });
                            }
                        ).finally(() => {
                            this.setState({
                                isLoading: false
                            });
                        });
                    } else {
                        this.setState({
                            isLoading: false
                        });
                    }
                });
            AccountService.addRecentlyViewedAccounts(accountNumber, authHeader);
        }
    };

    getAccountHolderName = () => {
        const { accountData } = this.state;

        if (_.isEmpty(accountData)) {
            return null;
        }

        const iconName = 'mi-account_circle';
        const accountHolderName = `${accountData.accountHolder.person.displayName}`;

        return (
            <React.Fragment>
                <Icon icon={iconName} className={styles.accountIcon} />
                {accountHolderName}
            </React.Fragment>
        );
    };

    getBillingPaymentIcon = () => {
        const { accountBillingData } = this.state;
        let isDelinquent = false;

        if (!_.isEmpty(accountBillingData) && !_.isEmpty(accountBillingData.ownedPolicies)) {
            isDelinquent = accountBillingData.ownedPolicies.length > 0
                && _.filter(accountBillingData.ownedPolicies, {
                    isDelinquent: true
                }).length > 0;
        }

        if (!_.isEmpty(accountBillingData.unownedPolicies)) {
            isDelinquent = accountBillingData.unownedPolicies.length > 0
                && _.filter(accountBillingData.unownedPolicies, {
                    isDelinquent: true
                }).length > 0;
        }

        return isDelinquent ? 'mi-warning' : 'mi-check';
    };

    getRoutesWithFeature() {
        const routes = [...pageRouting];

        this.commissionsTurnedOn(routes);
        this.billingFeatureTurnedOn(routes);
        this.claimFeatureTurnedOn(routes);

        return routes;
    }

    commissionsTurnedOn = (routes) => {
        const { commissionTurnedOn } = this.state;

        if (
            commissionTurnedOn
            && isCapabilityEnabled({ capabilitiesConfig, capabilityName: 'commission' })
        ) {
            const route = {
                path: '/commission',
                component: AccountCommission
            };

            routes.push(route);
        }
    };

    billingFeatureTurnedOn = (routes) => {
        if (isCapabilityEnabled({ capabilitiesConfig, capabilityName: 'billing' })) {
            const billingRoute = {
                path: '/billingAndPayment',
                componentPath: 'Accounts/BillingAndPayment/BillingAndPayment'
            };

            routes.push(billingRoute);
        }
    };

    claimFeatureTurnedOn = (routes) => {
        if (this.isClaimsTurnedOn()) {
            const claimRoute = {
                path: '/claims',
                component: AccountClaims
            };

            routes.push(claimRoute);
        }
    };

    updateCountInfo = (type) => {
        const { accountData } = this.state;
        const currentCount = _.get(accountData, `numberOf${type}`);

        _.set(accountData, `numberOf${type}`, currentCount + 1);
        this.setState({ accountData });
    };

    handleTilesOnClick = (id) => {
        this.setState({ currentView: id });
    };

    canAccessActivities = () => {
        const { authUserData } = this.props;

        return authUserData?.permissions_Ext.includes('actview_ext');
    };

    getMetadataImplementation = () => {
        const {
            commissionTurnedOn,
            accountData
        } = this.state;
        const { currentView } = this.state;
        const overrides = {
            [currentView]: {
                active: true
            },
            commission: {
                active: currentView === 'commission',
                visible: commissionTurnedOn && isCapabilityEnabled({ capabilitiesConfig, capabilityName: 'commission' })
            },
            billingAndPayment: {
                active: currentView === 'billingAndPayment',
                tileIcon: this.getBillingPaymentIcon(),
                visible: isCapabilityEnabled({ capabilitiesConfig, capabilityName: 'billing' })
            },
            claims: {
                active: currentView === 'claims',
                visible: this.isClaimsTurnedOn()
            },
            accountHeaderTitle: {
                content: this.getAccountHolderName()
            },
            activities: {
                visible: this.canAccessActivities()
            }
        };
        const resolvers = {
            resolveCallbackMap: {
                getCell: this.getCell,
                getAccount: this.getAccount,
                getPolicyLink: this.getPolicyLink,
                handleTilesOnClick: this.handleTilesOnClick,
                handleValueChange: this.handleValueChange,
                handleLandingTilesOnClick: this.handleLandingTilesOnClick
            }
        };

        return (
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={accountData}
                overrideProps={overrides}
                callbackMap={resolvers.resolveCallbackMap}
            />
        );
    };

    isClaimsTurnedOn = () => isCapabilityEnabled({ capabilitiesConfig, capabilityName: 'claim' });

    updateCurrentView(currentPage) {
        const excludePage = ['contactDetails'];

        if (excludePage.indexOf(currentPage) !== -1) {
            return;
        }

        this.setState({
            currentView: currentPage
        });
    }

    render() {
        const { accountData, isLoading, accountBillingData } = this.state;

        if (isLoading) {
            return <E1PLoader loaded={!isLoading} />;
        }

        const {
            match: { url }
        } = this.props;
        const accountActivitiesData = {
            accountData,
            getUpdateCountInfo: this.updateCountInfo
        };

        return (
            <React.Fragment>
                {this.getMetadataImplementation()}
                <CommonRoutes
                    steps={this.getRoutesWithFeature()}
                    basePath={url}
                    accountDetailsData={accountData}
                    accountActivitiesData={accountActivitiesData}
                    billingData={accountBillingData}
                />
            </React.Fragment>
        );
    }
}
export const AccountsSummary = AccountDetails;
export default withAuthenticationContext(AccountDetails);
