
import React, { Component } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { TranslatorContext } from '@jutro/locale';
import { PolicyService } from 'gw-capability-gateway';
import { withAuthenticationContext } from '@xengage/gw-digital-auth-react';
import { LobIconUtil } from '@xengage/gw-portals-util-js';
import { PolicyClaims } from 'gw-capability-gateway-claim-react';
import { isCapabilityEnabled } from '@xengage/gw-portals-config-js';
import { withModalContext } from '@jutro/components';
import { E1PLoader } from 'e1p-capability-policyjob-react';
import { GatewayDocumentService } from 'gw-capability-gateway-document';
import { DependencyProvider } from '@xengage/gw-portals-dependency-react';
import { ContingencyService } from 'e1p-capability-gateway';
import { ViewModelForm, withViewModelService } from '@xengage/gw-portals-viewmodel-react';
import appConfig from 'app-config';
import DocumentsPage from '../E1PDocuments/Documents';
import FormattedHeaderComponent from '../../Components/FormattedHeaderComponent/FormattedHeaderComponent';
import CommonRoutes from '../../Common/CommonRouting';
import metadata from './PolicyDetails.metadata.json5';
import styles from '../Policies.module.scss';
import messages from '../Policies.messages';
import pageRouting from '../../Common/Policies-config.json5';

const { capabilitiesConfig } = appConfig;

const DocumentPageService = {
    getDocuments: PolicyService.getDocumentsForPolicy,
    generateUploadToken: GatewayDocumentService.generateUploadToken,
    uploadDocument: GatewayDocumentService.uploadDocument,
    removeDocument: GatewayDocumentService.removeDocument,
    downloadDocument: GatewayDocumentService.downloadDocument
};

function documentFeatureTurnedOn(routes) {
    const documentRoute = {
        path: '/documents',
        component: DocumentsPage
    };

    if (isCapabilityEnabled({ capabilitiesConfig, capabilityName: 'document' })) {
        routes.push(documentRoute);
    }
}

class PolicyDetailsWithoutModalContext extends Component {
    static contextType = TranslatorContext;

    static propTypes = {
        match: PropTypes.shape({
            path: PropTypes.string,
            params: PropTypes.shape({
                policyNumber: PropTypes.string
            }),
            url: PropTypes.string
        }).isRequired,
        authHeader: PropTypes.shape({}),
        isAuthenticated: PropTypes.bool,
        location: PropTypes.shape({
            state: PropTypes.string,
            pathname: PropTypes.string
        }).isRequired,
        authUserData: PropTypes.shape({
            publicID: PropTypes.string
        }),
        viewModelService: PropTypes.shape({
            create: PropTypes.func,
            productMetadata: PropTypes.shape({
                get: PropTypes.func
            })
        }).isRequired,
        jobNumber: PropTypes.string
    };

    state = {
        policyResponse: {},
        policyBillingData: {},
        currentView: undefined,
        isLoading: true,
        contingencies: [],
        outstandingContingenciesPresent: false,
        prerenewalDirectionTypeValues: [],
        jobNumber: undefined
    };

    componentDidMount() {
        this.getPolicyDetails();
        this.retrieveContingencies();
        this.getPreRenewalDirectionTypeNames();
    }

    // Gets PreRenewalDirection typekey's values and storing into state variable
    getPreRenewalDirectionTypeNames = () => {
        const { viewModelService } = this.props;
        const translator = this.context;
        const preRenewalDirectionTypeAvailableValues = viewModelService.productMetadata.get('pc')
            .types.getTypelist('PreRenewalDirection')
            .codes
            .map((item) => ({
                code: item.code,
                name: translator({
                    id: item.name,
                    defaultMessage: item.name
                })
            }));

        this.setState({
            prerenewalDirectionTypeValues: preRenewalDirectionTypeAvailableValues
        });
    }

    getAccountHolderNameandNumber = (accountData, type) => {
        if (_.isEmpty(accountData)) {
            return null;
        }

        if (type === 'status') {
            return accountData.latestPeriod.displayStatus;
        }

        return `${accountData.product.productName} (${accountData.policyNumber})`;
    };

    getRoutesWithFeature() {
        const routes = [...pageRouting];

        this.claimFeatureTurnedOn(routes);
        documentFeatureTurnedOn(routes);

        return routes;
    }

    canAccessInteractive = () => {
        const { authUserData } = this.props;

        return authUserData?.permissions_Ext.includes('interactivelettercreate') && authUserData?.permissions_Ext.includes('doccreate');
    };

    canAccessActivities = () => {
        const { authUserData } = this.props;

        return authUserData?.permissions_Ext.includes('actview_ext');
    };

    claimFeatureTurnedOn = (routes) => {
        if (this.isClaimsTurnedOn()) {
            const claimRoute = {
                path: '/claims',
                component: PolicyClaims
            };

            routes.push(claimRoute);
        }
    };

    updateCountInfo = (type, substract = false) => {
        const { policyResponse } = this.state;
        const currentCount = _.get(policyResponse, `numberOf${type}`);

        if (substract) {
            _.set(policyResponse, `numberOf${type}`, currentCount - 1);
        } else {
            _.set(policyResponse, `numberOf${type}`, currentCount + 1);
        }

        this.setState({ policyResponse });
    };

    updatePreRenewalDirection = (preRenewalDirection) => {
        const { policyResponse } = this.state;

        _.set(policyResponse, 'preRenewalDirection_Ext', preRenewalDirection);
        this.setState({ policyResponse });
    };

    setOutstandingContingenciesPresent = (outstandingContingenciesPresentParam) => {
        this.setState({
            outstandingContingenciesPresent: outstandingContingenciesPresentParam
        });
    };

    // added this callback function to set currentView if we directly going from
    // one tile to another without clicking on tile itself.
    redirectToAnotherTile = (id) => {
        const { currentView } = this.state;

        if (currentView !== id) {
            this.setState({
                currentView: id
            });
        }
    };

    retrieveContingencies = async () => {
        this.setState({
            isLoading: true
        });

        const {
            authHeader,
            match: {
                params: { policyNumber }
            }
        } = this.props;

        ContingencyService.retrieveContingenciesForPolicy(policyNumber, authHeader)
            .then((contingeciesForPolicy) => {
                if (contingeciesForPolicy?.length > 0) {
                    this.setState({
                        contingencies: contingeciesForPolicy,
                        outstandingContingenciesPresent: contingeciesForPolicy.some((contingency) => contingency.contingencyStatus === 'Pending')
                    });
                }
            }).catch(() => {
                this.props.modalContext.showAlert({
                    status: 'error',
                    icon: 'mi-error-outline',
                    title: messages.contingenciesRetrieveErrorTitle,
                    message: messages.contingenciesRetrieveErrorMessage
                });
            }).finally(() => {
                this.setState({
                    isLoading: false
                });
            });
    };

    getPolicyDetails = async () => {
        this.setState({
            isLoading: true
        });

        const {
            isAuthenticated,
            authHeader,
            match: {
                params: { policyNumber }
            },
            location: { state: redirectPath, pathname }
        } = this.props;

        if (isAuthenticated) {
            const getPolicyResponse = await PolicyService.getPolicy(policyNumber, authHeader);
            const routePath = pathname && (pathname.trim().split('/') || []);
            const selectedPageFromPath = routePath[routePath.length - 1];
            const activeTile = selectedPageFromPath || redirectPath || 'summary';

            this.setState({
                currentView: activeTile,
                policyResponse: getPolicyResponse
            });
        }

        await PolicyService.addRecentlyViewedPolicy(policyNumber, authHeader);
        this.setState({
            isLoading: false
        });
    };

    isClaimsTurnedOn = () => isCapabilityEnabled({ capabilitiesConfig, capabilityName: 'claim' });

    handleTilesOnClick = (id) => {
        this.setState({ currentView: id });
    };

    setJobNumber = (jobNumberValue) => {
        this.setState({
            jobNumber: jobNumberValue
        });
    }

    render() {
        const {
            policyResponse,
            currentView,
            isLoading,
            policyBillingData,
            contingencies,
            outstandingContingenciesPresent,
            prerenewalDirectionTypeValues,
            jobNumber
        } = this.state;
        const productName = _.get(policyResponse, 'product.productCode');
        const isAbinito = _.get(policyResponse, 'latestPeriod.cancellationReason_Ext') === 'abInitio_ext';

        if (isLoading) {
            return <E1PLoader loaded={!isLoading} />;
        }

        if (_.isEmpty(policyResponse)) {
            return null;
        }

        const overrides = {
            [currentView]: {
                active: true
            },
            claims: {
                active: currentView === 'claims',
                visible: this.isClaimsTurnedOn()
            },
            policyProductName: {
                content: this.getAccountHolderNameandNumber(policyResponse, '')
            },
            policyStatus: {
                message: this.getAccountHolderNameandNumber(policyResponse, 'status')
            },
            accountHeader: {
                redirectPath: `/accounts/${policyResponse.account.accountNumber}/summary`,
                displayName: policyResponse.account.accountHolder.displayName,
                message: messages.accountNavigation
            },
            policyIcon: {
                icon: LobIconUtil.getMaterialIcon(productName)
            },
            abInitoCancelledMessageDiv: {
                visible: isAbinito
            },
            contingenciesInfoMessageDiv: {
                visible: outstandingContingenciesPresent
            },
            interactiveLetter: {
                visible: this.canAccessInteractive(),
                active: currentView === 'interactiveLetter',
            },
            activities: {
                visible: this.canAccessActivities(),
                active: currentView === 'activities',
            }
        };

        const resolvers = {
            resolveComponentMap: {
                accountheadercomponent: FormattedHeaderComponent
            },
            resolveCallbackMap: {
                handleTilesOnClick: this.handleTilesOnClick
            },
            resolveClassNameMap: styles
        };
        const {
            match: { url }
        } = this.props;
        const policyDetailsData = {
            policyResponse,
            getUpdateCountInfo: this.updateCountInfo,
            contingencies,
            updatePreRenewalDirection: this.updatePreRenewalDirection,
            getPolicyDetails: this.getPolicyDetails,
            prerenewalDirectionTypeValues
        };

        return (
            <DependencyProvider value={{ DocumentPageService }}>
                <ViewModelForm
                    uiProps={metadata.pageContent}
                    model={policyResponse}
                    overrideProps={overrides}
                    callbackMap={resolvers.resolveCallbackMap}
                    componentMap={resolvers.resolveComponentMap}
                    classNameMap={resolvers.resolveClassNameMap}
                />
                <CommonRoutes
                    steps={this.getRoutesWithFeature()}
                    basePath={url}
                    policyNumber={policyResponse.policyNumber}
                    policyDetailsData={policyDetailsData}
                    billingData={policyBillingData}
                    setOutstandingContingenciesPresent={this.setOutstandingContingenciesPresent}
                    redirectToAnotherTile={this.redirectToAnotherTile}
                    setJobNumber={this.setJobNumber}
                    jobNumber={jobNumber}
                />
            </DependencyProvider>
        );
    }
}

const PolicyDetails = withModalContext(PolicyDetailsWithoutModalContext);

export default withViewModelService(withAuthenticationContext(PolicyDetails));
